import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import cssVarResizeInit from './core/css-var-resize';
import setCurrentYear from './core/current-year';

// @ts-check

export default {
  init: () => {
    window.Alpine = Alpine;

    Alpine.plugin(focus);
    Alpine.start();
    cssVarResizeInit();
    setCurrentYear();
  },
};
