/// <reference path="../index.d.ts" />
import { Loader } from '@googlemaps/js-api-loader';

// @ts-check

const C = {
  id: Math.random().toString(36).substring(2),
  attributes: {
    maps: 'data-map',
    markers: 'data-marker',
  },
  defaultConfig: {
    map: {
      lat: 0,
      lng: 0,
      zoom: 10,
      markers: [],
    },
    marker: {
      lat: 0,
      lng: 0,
    },
  },
};

/**
 * Parse JSON config.
 * @param {string | null} mapConfig
 * @param {'map' | 'marker'} type
 * @returns {MapConfig}
 */
const parseConfig = (config, type = 'map') => {
  if (!config) return C.defaultConfig[type];

  try {
    return {
      ...C.defaultConfig[type],
      ...JSON.parse(config),
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not parse ${type} config. Please ensure its valid JSON.`,
      e.message
    );
    return C.defaultConfig[type];
  }
};

/**
 * Initialise Google Maps
 * @param {string} apiKey
 */
const initMaps = async (apiKey) => {
  if (!apiKey) {
    // eslint-disable-next-line no-console
    console.warn(
      'Google Maps API key not found. Please check your theme map initialisation.'
    );
  }

  const loader = new Loader({
    apiKey: apiKey,
    version: 'weekly',
    libraries: ['marker', 'maps'],
  });

  const [maps, markers] = await Promise.all([
    loader.importLibrary('maps'),
    loader.importLibrary('marker'),
  ]);

  const mapElements = document.querySelectorAll(`[${C.attributes.maps}]`);
  for (let i = 0; i < mapElements.length; i++) {
    const mapEle = mapElements[i];
    const markerELes = mapEle.querySelectorAll(`[${C.attributes.markers}]`);

    const config = parseConfig(mapEle.getAttribute(C.attributes.maps), 'map');

    // -------------------------------------
    // Initialise map

    const map = new maps.Map(mapEle, {
      mapId: mapEle.id || C.id,
      center: {
        lat: config.lat,
        lng: config.lng,
      },
      zoom: config.zoom,
    });

    // -------------------------------------
    // Spawn custom and standard markers

    for (let j = 0; j < markerELes.length; j++) {
      const markerEle = markerELes[j];
      const markerConfig = parseConfig(
        markerEle.getAttribute(C.attributes.markers),
        'marker'
      );
      new markers.AdvancedMarkerElement({
        map: map,
        position: {
          lat: markerConfig.lat,
          lng: markerConfig.lng,
        },
        content: markerEle,
      });
    }
    for (let j = 0; j < config.markers.length; j++) {
      new markers.AdvancedMarkerElement({
        map: map,
        position: {
          lat: config.markers[j].lat,
          lng: config.markers[j].lng,
        },
        title: config.markers[j].title,
      });
    }
  }
};

export default {
  init: initMaps,
};
