// @ts-check

/**
 * Sets the current year in the DOM
 */
const setCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  const eles = document.querySelectorAll('[data-current-year]');
  for (const ele of eles) {
    ele.innerHTML = currentYear.toString();
  }
};

export default setCurrentYear;
