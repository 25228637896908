import core from '@gritdigital/ui/core'
import splide from '@gritdigital/ui/packages/splide'
import maps from '@gritdigital/ui/packages/maps'
import '@gritdigital/ui/packages/lite-players'
import { ajaxFilter } from './inc/ajaxFilter.js'
import { quantity } from './inc/quantity.js'
import { voucher } from './inc/voucher.js'
import { getCartCount } from './inc/getCartCount.js'

// @ts-check

splide.init()
maps.init('AIzaSyBr4gYm5BhHLoVhSPtT9516Y_wdpgSWUxo')
core.init()

ajaxFilter();
quantity();
voucher();
getCartCount();

// WooCommerce jQuery events
if (typeof jQuery !== 'undefined') {
    //@ts-ignore
    jQuery(document.body).on('updated_cart_totals', function (event) {
        quantity();
        getCartCount();
    })
    jQuery(document.body).on('updated_checkout', function (event) {
        quantity();
        voucher();
        getCartCount();
    })
}
