// @ts-check

/**
 * Observer resize handler
 * @param {ResizeObserverEntry} entry
 */
const resizeHandler = (entry) => {
  const element = entry.target;
  const variable = entry.target.getAttribute('data-css-var-resize');
  if (!variable) return;

  switch (variable) {
    // case '--ui-header-height': { break; }
    default: {
      const targetHeight = element.getBoundingClientRect().height;
      document.documentElement.style.setProperty(variable, `${targetHeight}px`);
      break;
    }
  }
};

/**
 * Handles updating CSS variables on target element resize observation.
 */
const cssVarResizeInit = () => {
  const elements = document.querySelectorAll('[data-css-var-resize]');
  if (elements.length === 0) return;

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      resizeHandler(entry);
    }
  });
  for (let i = 0; i < elements.length; i++) {
    resizeObserver.observe(elements[i]);
  }
};

export default cssVarResizeInit;
