import splide from '@splidejs/splide';

// @ts-check

/**
 * Looks for elements with the class `splide` and initialises a new Splide instance for each.
 */
const initialiseSplide = () => {
  const sliders = [...document.getElementsByClassName('g-splide')];

  if (sliders) {
    sliders.forEach((slider) => {
      let splideInstance = new splide(slider);

      splideInstance.mount();

      // Gallery
      if (slider.classList.contains('g-gallery-splide-goto')) {
        window.addEventListener('click', (event) => {
          const splideId = event.target.dataset.splideId;
          const splideGoto = event.target.dataset.splideGoto;

          if (splideId && splideGoto && splideId === splideInstance.root.id) {
            splideInstance.go(parseInt(splideGoto));
          }
        });
      }

      // Height fit
      if (slider.classList.contains('g-splide--height-fit')) {
        let updateHeight = (newIndex) => {
          let slide = splideInstance.Components.Slides.getAt(
            typeof newIndex == 'number' ? newIndex : splideInstance.index
          ).slide;
          slide.parentElement.parentElement.style.height =
            slide.offsetHeight + 'px';
        };

        splideInstance.on('move resize', updateHeight);
        updateHeight();
      }
    });
  }
};

export default {
  init: initialiseSplide,
};
