export const getCartCount = (url = null) => {
    fetch('/wp-admin/admin-ajax.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'action=fetch_cart_count',
    })
        .then(response => response.json())
        .then(data => {
            // Handle the retrieved cart items
            let counters = document.querySelectorAll('[data-cart-count]');

            counters.forEach(counter => {
                counter.setAttribute('data-cart-count', data);
                if(data > 0) {
                    counter.innerHTML = data.toString();
                    counter.style.display = 'flex';
                } else {
                    counter.style.display = 'none';
                }
            })

            // Customize further processing based on your requirements
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle the error
        });
}
