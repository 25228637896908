export const quantity = () => {

    // Find the quantity input field
    const quantityInputs = document.querySelectorAll('.woocommerce .quantity input[type="number"]');

    if (!quantityInputs.length) {
        return;
    }

    const quantityUpdate = (quantityInput) => {
        if (document.getElementsByName('update_cart').length > 0) {
            console.log('after cart has been updated');
            let update_cart = document.getElementsByName('update_cart')[0];
            update_cart.disabled = false;
            update_cart.classList.add('update_cart');
        } else {
            quantityInput.dispatchEvent(new Event('change', {bubbles: true}));
        }
    }

    // Loop through each input field
    quantityInputs.forEach(quantityInput => {

        // Create minus and plus buttons
        const minusButton = document.createElement('button');
        minusButton.textContent = '−';
        minusButton.classList.add('quantity-button');
        minusButton.setAttribute('data-quantity', 'minus');

        const plusButton = document.createElement('button');
        plusButton.textContent = '+';
        plusButton.classList.add('quantity-button');
        plusButton.setAttribute('data-quantity', 'plus');

        quantityInput.parentNode.insertBefore(minusButton, quantityInput);
        quantityInput.parentNode.insertBefore(plusButton, quantityInput.nextSibling);

        minusButton.addEventListener('click', function (e) {
            e.preventDefault();
            const currentValue = parseInt(quantityInput.value);
            if (!isNaN(currentValue) && currentValue > 1) {
                quantityInput.value = (currentValue - 1).toString();
                quantityUpdate(quantityInput);
            }
        });

        plusButton.addEventListener('click', function (e) {
            e.preventDefault();
            const currentValue = parseInt(quantityInput.value);
            if (!isNaN(currentValue)) {
                quantityInput.value = (currentValue + 1).toString();
                quantityUpdate(quantityInput);
            }
        });
    });

}
