export const voucher = (form) => {
    let orderInput = document.querySelector('#order_coupon_code')

    if (!orderInput) {
        return;
    }

    let couponInput = document.querySelector('form.checkout_coupon input[name="coupon_code"]')
    let checkoutButton = document.querySelector('.order-review-coupon-form #apply_coupon')
    let couponForm = document.querySelector('form.checkout_coupon button')

    orderInput.oninput = function (e) {
        couponInput.value = this.value
    }
    checkoutButton.addEventListener('click',  function (e) {
        e.preventDefault();
        couponForm.click();
    })
}
