import { serialize } from './support'

export const ajaxFilter = (url = null) => {
    /**
     * Ajax filters
     */
    const ajaxFilters = document.querySelectorAll('[data-ajax="filter"]')
    const ajaxContent = document.querySelectorAll('[data-update]')

    const updateWrapper = () => {
        let wrapper = document.querySelector('.calculator-wrapper');

        if (wrapper) {
            if (wrapper.querySelector('form')) {
                wrapper.classList.remove('bg-brand-dark')
                wrapper.classList.add('bg-brand-grey-lightest')
            } else {
                wrapper.classList.remove('bg-brand-grey-lightest')
                wrapper.classList.add('bg-brand-dark')
            }
        }
    }

    const ajaxForms = () => {
        const ajaxForms = document.querySelectorAll('[data-ajax="form"]')
        ajaxForms.forEach(function (form) {
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                console.log('submitted');
                triggerFilter(form)
            })
        })
    }

    const removePlaceholderBackgroundImages = () => {
        document.querySelectorAll("[data-blur-placeholder]").forEach(e=>{e.complete?e.style.backgroundImage="none":e.addEventListener("load",()=>{e.style.backgroundImage="none"})});
    }

    updateWrapper();
    ajaxForms();

    const ajaxFetch = (url) => {

        const xmlhttp = new XMLHttpRequest()

        ajaxContent.forEach(function (element) {
            element.classList.add('is-loading')
            ajaxContent[0].scrollIntoView({ behavior: 'smooth' });
        })

        // Get focused element
        const activeElement = document.activeElement
        let activeControl = null

        if (activeElement.matches('select, input')) {
            activeControl = activeElement.id
        }

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                if (xmlhttp.status === 200) {

                    const parser = new DOMParser()
                    const htmlDocument = parser.parseFromString(xmlhttp.response, 'text/html')

                    const timeout = 200

                    setTimeout(function () {
                        ajaxContent.forEach(function (element) {
                            let name = element.getAttribute('data-update')

                            element.innerHTML = htmlDocument.documentElement.querySelector('[data-update="' + name + '"]').innerHTML
                            element.classList.remove('is-loading')
                            window.history.pushState('', '', url)

                            if (document.getElementById(activeControl)) {
                                console.log(document.getElementById(activeControl))
                                document.getElementById(activeControl).focus()
                            }

                            updateWrapper();
                            ajaxForms();
                            removePlaceholderBackgroundImages();

                        })

                    }, timeout)

                } else if (xmlhttp.status === 400) {
                    console.warn('There was an error 400')
                } else {
                    console.warn(xmlhttp.status)
                }
            }
        }

        xmlhttp.open('GET', url, true)
        xmlhttp.send()
    }

    document.addEventListener('click', function (e) {

        const target = e.target

        if (target.matches('.pagination a') || target.matches('.ajax-tabs a')) {
            e.preventDefault()
            ajaxFetch(target.getAttribute('href'))
        }
    })

    ajaxFilters.forEach(function (filter) {

        let buttonRefresh = filter.querySelector('[data-ajax="hidden"]')

        if (buttonRefresh) {
            buttonRefresh.parentNode.removeChild(buttonRefresh)
        }

        filter.addEventListener('change', function (e) {
            triggerFilter(filter)
        })
    })

    const triggerFilter = (filter) => {
        let params = serialize(filter)

        if (params) {
            params = filter.getAttribute('action').indexOf('?') > -1 ? '&' + params : '?' + params
        } else {
            params = '?all'
        }

        ajaxFetch(filter.getAttribute('action') + params)
    }

}
